<template>
  <main v-if="page" ref="horizontalLayoutContainer" class="horizontal-layout">
    <div ref="innerContainer" class="inner">
      <HeaderModule
        v-if="page.headerModule"
        class="module"
        :module="page.headerModule"
        :horizontal-scroll-timeline="timeline ?? undefined"
      />
      <Modules
        :modules="page.modules"
        :horizontal-scroll-timeline="timeline ?? undefined"
      />
      <SiteFooterHorizontal />
    </div>
  </main>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { useSeo } from '~/utils/useSeo';
import HeaderModule from '~/components/header-modules/HeaderModule.vue';
import { useHorizontalScrolling } from '~/utils/useHorizontalScrolling';
import SiteFooterHorizontal from '~/components/site-footer/SiteFooterHorizontal.vue';
import { useSiteHeaderBackground } from '~/utils/useSiteHeaderBackground';
const i18n = useI18n();

const { data, error } = await useAsyncGql({
  operation: 'GetHorizontalPageByTranslatedSlug',
  variables: { slug: 'home', locale: i18n.localeProperties.value.siteLocale },
});

const page = data.value?.horizontalPage;

if (!page || error.value) {
  showError({
    statusCode: 404,
  });
}

if (page) {
  useSeo(page._seoMetaTags ?? []);
}

const horizontalLayoutContainer = ref<HTMLElement | null>(null);
const innerContainer = ref<HTMLElement | null>(null);

const { timeline } = useHorizontalScrolling(innerContainer, {
  viewport: 'vl',
});

definePageMeta({
  pageTransition: {
    mode: 'out-in',
    onLeave(el, done) {
      const transitionEl = el.querySelector('[data-transition-element]');

      if (transitionEl instanceof HTMLElement) {
        const transitionSiblingsLeft = el.querySelectorAll(
          '[data-sibling="left"]',
        );
        const transitionSiblingsRight = el.querySelectorAll(
          '[data-sibling="right"]',
        );

        const headerHero = el.querySelector('.header-hero');
        const teaserAbout = el.querySelector('.teaser-about-us');

        gsap.set(transitionEl, {
          visibility: 'hidden',
          delay: 0.75,
        });

        const tl = gsap.timeline({
          onComplete: done,
        });

        tl.to(el, {
          opacity: 1,
          duration: 2.5,
        })
          .to(
            [headerHero, teaserAbout],
            {
              opacity: 0,
            },
            '<',
          )
          .to(
            transitionSiblingsLeft,
            {
              x: window.innerWidth * -1.1,
              delay: 0.25,
              ease: 'power3.in',
            },
            '<',
          )
          .to(
            transitionSiblingsRight,
            {
              x: window.innerWidth * 1.1,
              ease: 'power3.in',
            },
            '<',
          );
      } else {
        done();
      }
    },
  },
});

useSiteHeaderBackground('transparent', { onVs: 'opaque' });
</script>

<style scoped lang="scss">
.horizontal-layout {
  @media (--vs) {
    max-width: 100vw;
    overflow-x: clip;
  }

  @media (--vl) {
    --margin-top: max(4.4rem, 14.222vh);

    width: 100%;
    height: 100lvh;
    position: absolute;
    inset: 0;
    overflow-x: clip;

    .inner {
      width: max-content;
      height: 100%;
      display: flex;
      gap: 6rem;
      overflow-y: clip;
    }
  }
}
</style>

<style lang="scss">
.horizontal-layout + .site-footer {
  @media (--vs) {
    --color-reticent: var(--color-white);

    background-color: var(--color-stone-900);
    color: var(--color-white);

    > .logo {
      filter: invert(1);
    }
  }

  @media (--vl) {
    display: none;
  }
}
</style>
