<template>
  <header ref="headerHeroEl" class="header-hero" data-horizontal-module>
    <h1 class="title">
      <span class="line line1 text-4xl"
        ><span class="word">{{ data.titleLine1 }} </span></span
      >
      <span class="line line2 text-4xl-display"
        ><span class="word">{{ data.titleLine2 }}</span>
        <picture class="arrow _hidden-vs">
          <source
            media="(max-width: 999px)"
            srcset="/icons/arrow-right-short.svg"
          />
          <source media="(min-width: 1000px)" srcset="/icons/arrow-right.svg" />
          <img src="/icons/arrow-right.svg" alt="" />
        </picture>
      </span>
      <span class="line line3 text-4xl">
        <span class="inner word">
          <span class="word-carousel">
            <span
              v-for="w in words"
              :key="w"
              :class="{ '-visible': w === currentWord }"
              class="cword"
              >{{ w }}</span
            >
          </span>
        </span>
      </span>
    </h1>
    <div class="content">
      <StructuredText
        v-if="data.text"
        class="text text-md"
        :data="data.text"
        :render-block="renderBlock"
      />
      <img alt="Arrow" class="arrow _hidden-vl" src="/icons/arrow-right.svg" />
    </div>
  </header>
</template>

<script setup lang="ts">
import { StructuredText } from 'vue-datocms';
import gsap from 'gsap';
import type { HeaderHeroFragment } from '#gql';
import renderBlock from '~/datocms/renderBlock';
import { useWordCarousel } from '~/utils/useWordCarousel';
import { EaseOutQuint } from '~/utils/EaseOutQuint';
import { removeTimelineAnimation } from '~/utils/removeTimelineAnimation';

const props = defineProps<{
  data: HeaderHeroFragment;
  horizontalScrollTimeline: gsap.core.Timeline;
}>();

const { isVl } = useViewportSize();

const { words, currentWord, startAnimation } = useWordCarousel(
  String(props.data.titleLine3),
);

let tl: gsap.core.Timeline | null = null;
const tweens = ref<HTMLElement[]>([]);

const headerHeroEl = ref<HTMLElement | null>(null);

watch(isVl, function () {
  if (!isVl.value) {
    removeTimelineAnimation(tl, tweens);
  }
});

onMounted(async function () {
  await nextTick();

  addFadeInAnimation();

  if (isVl.value) {
    addScrollAnimation();
  }
});

function addScrollAnimation() {
  tl = gsap.timeline({
    ease: 'none',
    scrollTrigger: {
      trigger: headerHeroEl.value,
      containerAnimation: props.horizontalScrollTimeline,
      scrub: true,
      start: '0%',
      end: '80%',
      horizontal: true,
    },
  });

  const line2 = (headerHeroEl.value as HTMLElement).querySelector(
    '.line2',
  ) as HTMLElement;
  const content = (headerHeroEl.value as HTMLElement).querySelector(
    '.content',
  ) as HTMLElement;

  tweens.value.push(line2);
  tweens.value.push(content);

  tl.fromTo(
    '.header-hero .line2',
    {
      x: 0,
    },
    {
      x: -250,
    },
  ).fromTo(
    '.header-hero .content',
    {
      x: 0,
    },
    {
      x: -100,
    },
    '<',
  );
}

function addFadeInAnimation() {
  const words: HTMLElement[] = gsap.utils.toArray('.header-hero .word');
  const arrowVl = document.querySelector('.header-hero .arrow._hidden-vs');
  const arrowVs = document.querySelector('.header-hero .arrow._hidden-vl');
  const text = document.querySelector('.header-hero .text');

  const tl = gsap.timeline({
    paused: false,
  });

  tl.fromTo(
    words,
    {
      yPercent: 120,
      opacity: 1,
    },
    {
      opacity: 1,
      yPercent: 0,
      stagger: 0.24,
      ease: EaseOutQuint,
      duration: 1,
      onComplete: function () {
        startAnimation();
      },
    },
  )
    .to(
      words[1],
      {
        x: '2ch',
        ease: EaseOutQuint,
        duration: 1.75,
      },
      '<60%',
    )
    .fromTo(
      arrowVl,
      {
        clipPath: 'inset(0% 0% 0% 100%)',
        scaleY: 0,
        xPercent: -200,
        opacity: 1,
      },
      {
        scaleY: 1,
        xPercent: 0,
        clipPath: 'inset(0% 0% 0% 0%)',
        ease: EaseOutQuint,
        duration: 1.5,
      },
      '<',
    )
    .fromTo(
      arrowVs,
      {
        clipPath: 'inset(0% 0% 100% 0%)',
        scaleY: 0,
        yPercent: -200,
        opacity: 1,
      },
      {
        scaleY: 1,
        yPercent: 0,
        clipPath: 'inset(0% 0% 0% 0%)',
        ease: EaseOutQuint,
        duration: 1.5,
      },
      '<',
    )
    .fromTo(
      text,
      { opacity: 0, y: 25 },
      {
        opacity: 1,
        y: 0,
      },
      '<',
    );
}
</script>

<style scoped lang="scss">
.header-hero {
  .text {
    // initial configuration, will be overriden by gsap
    opacity: 0;
  }

  .arrow {
    // initial configuration, will be overriden by gsap
    opacity: 0;
  }

  @media (--vs) {
    display: grid;
    grid-template-rows: minmax(0, 1fr) minmax(0, auto);
    gap: 19.4285vh;
    padding: 0 var(--base-component-padding-inline);

    @media (max-aspect-ratio: 2/1) {
      min-height: 95svh;
    }

    @media (min-aspect-ratio: 2/1) {
      min-height: 140svh;

      > .title {
        padding-top: var(--site-header-height);
      }

      > .content {
        > .arrow {
          transform: translateX(calc(-100% - 19.4285vh));
        }
      }
    }

    > .title {
      align-self: flex-end;

      > .line {
        display: block;
        overflow-y: clip;
        line-height: 120%;
        position: relative;

        > .word {
          display: block;
          opacity: 0;
        }
      }

      > .line3 {
        display: block;

        > .inner {
          display: block;
          padding-top: 0.2rem;
        }
      }
    }

    > .content {
      display: flex;
      gap: 1rem;
      padding-bottom: 1.5rem;

      > .arrow {
        rotate: 90deg;
        width: 4.3125rem;
      }
    }
  }

  @media (--vl) {
    display: grid;
    grid-template-rows: minmax(0, auto) minmax(0, auto);
    grid-template-columns: minmax(0, 1fr) 25ch;
    width: calc(100vw - 5rem);
    height: 100%;

    > .title {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      align-self: flex-end;
      padding-left: var(--base-component-padding-inline);
      padding-bottom: 1rem;

      > .line {
        display: block;
        overflow-y: clip;
        line-height: 120%;
        position: relative;

        > .word {
          display: block;
          opacity: 0;
        }
      }

      > .line1 {
        transform: translateY(1rem);
      }

      > .line2 {
        transform: translateY(0.5rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100vw - 5rem);

        > .arrow {
          width: 12.1rem;
          max-width: 12vw;
        }
      }

      > .line3 {
        display: block;

        > .inner {
          display: block;
        }
      }
    }

    > .content {
      max-width: 25ch;
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      margin-top: var(--margin-top);
      //padding-right: var(--base-component-padding-inline);
    }
  }
}

.word-carousel {
  position: relative;
  display: block;
  height: fit-content;
  overflow-y: clip;
  // add padding to fix clipped äöü-bug
  padding: 1.25rem 0 0;
  margin-top: -0.25rem;
  transform: translateY(-1rem);

  @media (--vs) {
    height: 5rem;
  }

  @media (--vl) {
    height: 10.5rem;
  }

  > .cword {
    position: absolute;
    transition: transform 1200ms cubic-bezier(0.76, 0, 0.24, 1);

    &.-visible {
      opacity: 1;
      transform: translateY(0%);
    }

    &:not(.-visible) {
      opacity: 0;
      transform: translateY(140%);
    }
  }
}
</style>
